<template>
    <div>
        <h4 class="bold text-uppercase">Tích hợp Site đại lý</h4>
        <div class="row">
            <div :class="['col-md-8 col-12']">
                <h6>Nhận API Key để đăng kí website đại lý</h6>
                <input
                    class="form-control input mt-3"
                    disabled
                    type="text"
                    placeholder="Vì lý do bảo mật, chúng tôi tạm thời ẩn API key, vui lòng nhấn nút bên dưới để lấy API key"
                    required=""
                    v-model="apiKey"
                />
                <div class="row">
                    <div class="col-md-6 col-12 mt-4">
                        <button
                            @click="getAPI()"
                            :class="['btn btn-block btn-dark-blue btn-rounded font-bold']"
                            type="submit"
                        >
                            Lấy API
                        </button>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                        <button
                            v-show="apiKey"
                            @click="copyApi"
                            :class="['btn btn-block bg-orange btn-rounded font-bold']"
                            type="submit"
                        >
                            Copy API
                        </button>
                    </div>
                </div>
                <h6 class="mt-4">Địa chỉ IP trỏ tên miền</h6>
                <h6>Chú ý trỏ tên miền site đại lý về đúng IP phía dưới</h6>
                <div class="card card-info mt-4">
                    <div class="card-body">
                        <h6 class="bold text-center font-24 mb-0">{{ ip_domain }}</h6>
                    </div>
                </div>
                <div class="card card-red mt-4">
                    <div class="card-body">
                        <h6 class="bold text-center font-24 mb-0">
                            Nghiêm cấm các tên miền có chứa facebook và instagram<br />Để tránh bị Facebook và Instagram
                            kiện vì vi phạm bản quyền.
                        </h6>
                    </div>
                </div>
                <button
                    @click="showVideo = !showVideo"
                    :class="['btn btn-block bg-orange btn-rounded bold mt-4 mb-2']"
                    type="submit"
                >
                    Xem Video Hướng Dẫn
                </button>
                <iframe
                    v-show="showVideo"
                    class="mt-4"
                    width="100%"
                    height="300"
                    :src="linkHDSD"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                <datatable
                    class="mt-4"
                    locale="vi"
                    title="Danh sách Site đại lý"
                    :columns="columnsTableLogs"
                    :rows="listAgency"
                >
                    <th slot="thead-tr" class="text-center" style="vertical-align: middle">Thao Tác</th>
                    <td slot="tbody-tr" slot-scope="props" class="text-center" style="vertical-align: middle">
                        <button @click="resetPassword(props.row)" class="btn btn-danger btn-sm mr-2">
                            <i class="far fa-check-circle"></i> Reset Mật Khẩu
                        </button>
                        <button @click="handleSettings(props.row)" class="btn btn-orange btn-sm">
                            <i class="far fa-check-circle"></i> Cài đặt trang
                        </button>
                    </td>
                </datatable>
            </div>
            <div class="col-md-4 col-12">
                <div class="card card-orange shadow-0">
                    <div class="card-body">
                        <h6 class="bold">Hướng dẫn:</h6>
                        <div v-if="helpContent">
                            <h6 v-html="helpContent"></h6>
                        </div>
                        <div v-else>
                            <h6>
                                - Để sử dụng hệ thống bên mình bạn cần đăng ký 1 tài khoản ở trang.
                            </h6>
                            <h6>- Chú ý trỏ Domain về IP: {{ ip_domain }}</h6>
                            <h6>
                                - Đăng ký miễn phí, không tốn bất cứ 1 chi phí nào, ko phí duy trì
                            </h6>
                            <h6>
                                - Bạn xài bao nhiêu thì mua bấy nhiêu, không yêu cầu nạp tiền trước
                            </h6>
                            <h6>
                                - Bạn chỉ cần trỏ IP tên miền của bạn về địa chỉ IP: {{ ip_domain }} hệ thống sẽ tự động
                                nhận tên miền và tạo site ctv riêng cho bạn, không cần hosting
                            </h6>
                            <h6>
                                - Bạn vào site riêng bằng tên miền của bạn, chỉnh sửa các thông tin liên hệ và ngân hàng
                                để khách của bạn chuyển khoản
                            </h6>
                            <h6 class="mb-0">
                                - Cách làm việc giữa site tổng và site đại lý (xem kỹ):
                                <a target="_blank" :href="linkHDSD">{{ linkHDSD }}</a>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card card-info mt-3">
                    <div class="card-body">
                        <h6 class="font-bold text-center">VIDEO HƯỚNG DẪN</h6>
                        <iframe
                            class="mt-2"
                            width="100%"
                            height="300"
                            :src="linkHDSD"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="modal-pass-confirm"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalPassConfirm"
            aria-hidden="true"
            style="display: none;"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header bold">Xác Nhận Mật Khẩu</div>
                    <form @submit.stop.prevent="loginWithUsername">
                        <div class="modal-body">
                            <div class="card bg-orange p-3 pl-3 text-left">
                                <h6 v-if="agencyIndex">
                                    Vui lòng xác nhận lại mật khẩu để tiến hành <code class="bold">Reset Password</code>
                                </h6>
                                <h6 v-else>Vui lòng xác nhận lại mật khẩu để lấy <code class="bold">API key</code></h6>
                            </div>
                            <div class="form-group mt-3">
                                <div class="col-xs-12">
                                    <label>Username</label>
                                    <input
                                        class="form-control input"
                                        type="text"
                                        disabled
                                        placeholder="Tên tài khoản"
                                        v-model="user.username"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label>Password</label>
                                    <input
                                        class="form-control input-gray"
                                        type="password"
                                        required=""
                                        placeholder="Mật khẩu"
                                        v-model="password"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary mx-1" data-dismiss="modal">Đóng</button>
                            <button :class="['btn btn-primary mx-1']" type="submit">Xác Nhận</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getListSiteAgecy, resetPasswordSiteAgecy } from "../../api/agency"
import { login } from "../../api/auth"
import { catchError } from "../../helpers"
import datatable from "../../components/datatable/datatable"
export default {
    name: "home-dash-board",
    components: {
        datatable: datatable
    },
    data() {
        return {
            password: "",
            showApi: false,
            apiKey: "",
            ip_domain: "45.77.175.89",
            showVideo: false,
            listAgency: [],
            columnsTableLogs: [
                { choose: true, label: "STT", field: "index", numeric: true },
                { choose: true, label: "Domain", field: "domain_html", html: true },
                { choose: true, label: "Username", field: "username", html: true },
                { choose: true, label: "Khởi tạo", field: "created_at", timeago: true }
            ],
            agencyIndex: null,
            linkHDSD: null,
            helpContent: null
        }
    },
    computed: {
        site() {
            return this.$store.state.site.site
        },
        user() {
            return this.$store.state.user.user
        },
        api_token() {
            return this.$store.state.user.api_token
        }
    },
    created() {
        const configVideo =
            this.site && this.site.config && this.site.config.help_video_agency
                ? this.site.config.help_video_agency
                : null
        const configContent =
            this.site && this.site.config && this.site.config.help_content_agency
                ? this.site.config.help_content_agency
                : null
        if (configVideo) {
            this.linkHDSD = configVideo
        }
        if (configContent) {
            this.helpContent = configContent
        }
        this.getListAgency()
    },
    methods: {
        getListAgency: async function() {
            let data = await getListSiteAgecy()
            if (data.data && data.data.length > 0) {
                this.listAgency = data.data.map((item, index) => {
                    item.index = index + 1
                    item.domain_html = `<a href="${item.domain}" target="_blank">${item.domain}</a>`
                    return item
                })
            }
        },
        resetPassword: function(item) {
            this.agencyIndex = item
            $("#modal-pass-confirm").modal("show")
        },
        showLogDetail(item) {
            item.show_detail = !item.show_detail
        },
        getAPI: function() {
            this.agencyIndex = null
            $("#modal-pass-confirm").modal("show")
        },
        loginWithUsername: async function() {
            if (this.password.length < 1) {
                this.$toastr.error("Vui lòng nhập đầy đủ tài khoản và mật khẩu để đăng nhập bằng tài khoản")
                return
            }
            let postData = {
                username: this.user.username,
                password: this.password,
                site_id: this.site.id
            }
            let data = await login(postData)
            if (data.success) {
                if (this.agencyIndex) {
                    let param = {
                        token: this.api_token,
                        user_id_agency_lv2: this.agencyIndex.user_id
                    }
                    let reset = await resetPasswordSiteAgecy(param)
                    if (reset.success) {
                        this.$swal("Thành Công", reset.message, "success")
                        $("#modal-pass-confirm").modal("hide")
                    } else {
                        this.$swal("Lỗi", catchError(reset), "error")
                    }
                    return
                }
                this.$swal("Thành Công", "Xác nhận mật khẩu thành công", "success")
                this.apiKey = data.data.token
                this.showApi = true
                $("#modal-pass-confirm").modal("hide")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        copyApi() {
            this.$copyText(this.apiKey)
            this.$toastr.success("Đã copy Api !")
        },
        handleSettings: async function(site) {
            let data = {
                agency_domain: site.domain,
                agency_token: site.token
            }
            this.$store.commit("STORE_SET_AGENCY_INFO", data)
            this.$router.push("/agency-settings")
        }
    }
}
</script>
