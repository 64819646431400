import { request, requestLv2 } from "../utils/request"

export function getListSiteAgecy() {
    return request({
        url: "api/get-site",
        method: "get"
    })
}
export function resetPasswordSiteAgecy(data) {
    return requestLv2({
        url: "/reset-password",
        method: "post",
        data
    })
}
